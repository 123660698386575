@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap);
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #f5f5f5;
}

.carouselimg {
  height: 90vh;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .carouselimg {
    height: 35vh;
    width: 100%;
  }
}
a:link {
  text-decoration: none !important;
  cursor: pointer;
}

a {
  color: inherit;
}

.catecard {
  margin-top: 20px !important;
  width: 300px;
  margin: auto;
  height: 270px;
  position: relative;
  transition: transform 4s ease-in;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}
.cateimg {
  width: 100%;
  height: 235px;
  transition: transform 1s ease-in;
}
.cateimg:hover {
  transform: scale(1.2);
}
.catecard:before {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  transform: translateY(-100%);
  font-size: 25px;
  color: #fff;
  display: block;
  background-color: rgba(188, 143, 143, 0.137);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  transition: transform 0.4s ease-in;
  border: 4px solid white;
  justify-content: center;
  box-sizing: border-box;
}

.catecard:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-bottom: 25px;
  content: "Basakhmal & Sons";
  transform: translateY(100%);
  font-size: 25px;
  color: #fff;
  display: block;
  background-color: rgb(169, 169, 169, 0.137);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;
  transition: transform 0.4s ease-in;
  border: 4px solid white;
  justify-content: center;
  box-sizing: border-box;
}
.catecard:hover::before {
  transform: translateY(0%);
}
.catecard:hover::after {
  transform: translateY(0%);
}

.catecardtext {
  font-size: 500;
  background-color: #f5f5f5;
  position: absolute;
  font-weight: 1000;
  top: 215px;
  left: 0;
  padding-top: 12px;
  width: 100%;
  margin: auto;
  width: 300px;
  height: 50px;
}

.maps {
  position: relative;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 400px !important;
  /* overflow: hidden; */
}

.heading {
  font-size: 2rem;
  font-family: "Montserrat", sans-serif;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.subheading {
  font-size: 1.1rem;
  font-weight: 1000;
}

.contact {
  background-color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0 20px;
  padding: 0;
  transition: transform 4s ease-in;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.text {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 520;
}

.heading2 {
  font-size: 1.3rem;
  font-family: "Times New Roman", Times, serif;
  font-weight: 600;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 600px) {
  .mobileonly {
    display: none;
  }
}

.contacttext {
  font-size: 1.07rem;
}

.cards {
  border-radius: 4px;

  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow;
  padding: 14px 80px 18px 36px;
  cursor: pointer;
}

.cards:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}
.card-img-top {
  width: 100%;
  height: 45vh;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .card-img-top {
    width: 100%;
    height: auto;
  }

  .vt {
    display: inherit;
  }
  .abtimg {
    height: 310px;
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.page {
  height: 80vh;
}

@media screen and (min-device-width: 480px) {
  .abtimg {
    height: 60vh;
    width: 65vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

